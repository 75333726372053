import { Suspense } from 'react';
import { Profile } from '@alliance-disposal/transport-types';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import PageLoader from '../components/PageLoader/PageLoader';
import { S3Provider } from '../context';
import routes from '../utils/routes';
import ErrorBoundary from './error-boundary';
import AppAdminWrapper from './route-protections/app-admin-wrapper';
import AuthWrapper from './route-protections/auth-wrapper';
import PublicOnlyWrapper from './route-protections/public-only-wrapper';
import WaysteStandardWrapper from './route-protections/wayste-standard-wrapper';

export type RouterOutletContext = {
    userProfile: Profile.ProfileTransport;
    waysteLite: boolean;
    isAppAdmin: boolean;
};

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
    {
        path: '/',
        element: (
            <Suspense fallback={<PageLoader />}>
                <S3Provider>
                    <App />
                </S3Provider>
            </Suspense>
        ),
        errorElement: <ErrorBoundary />,
        children: [
            {
                element: <AuthWrapper />,
                children: [
                    {
                        path: routes.dashboard,
                        lazy: () => import('../pages/dashboard/dashboard').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.orders.list,
                        lazy: () => import('../pages/OrderList').then((module) => ({ Component: module.default })),
                    },
                    {
                        element: <AppAdminWrapper />,
                        children: [
                            {
                                path: routes.appAdmin.list,
                                lazy: () => import('../pages/app-admin/app-admin').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.lab.list,
                                lazy: () => import('../components/XXXLab').then((module) => ({ Component: module.default })),
                            },
                        ],
                    },
                    {
                        element: <WaysteStandardWrapper />,
                        children: [
                            {
                                path: routes.orders.create,
                                lazy: () => import('../pages/order-create').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.orders.update(':id'),
                                lazy: () => import('../components/OrderUpdate').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.roScheduling.list,
                                lazy: () => import('../pages/ro-scheduling/ro-schedule').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.driverTracking.list,
                                lazy: () =>
                                    import('../pages/driver-tracking/driver-tracking').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.timeSheet.list,
                                lazy: () =>
                                    import('../pages/time-sheet/time-sheet').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.equipment.vehicles,
                                lazy: () => import('../pages/equipment/vehicle-page').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.equipment.containers,
                                lazy: () => import('../pages/equipment/container-page').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.equipment.inventory,
                                lazy: () => import('../pages/equipment/inventory-page').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.equipment.map,
                                lazy: () => import('../pages/equipment/container-map').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.subscriptions.list,
                                lazy: () =>
                                    import('../pages/commercial-crm/subscription-list').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.subscriptions.create,
                                lazy: () =>
                                    import('../pages/commercial-crm/subscription-forms/subscription-create').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.subscriptions.editEquipment(':id'),
                                lazy: () =>
                                    import('../pages/commercial-crm/subscription-forms/subscription-equipment-update').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.subscriptions.details(':id'),
                                lazy: () =>
                                    import('../pages/commercial-crm/subscription-details').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.dashboard,
                                lazy: () =>
                                    import('../pages/commercial-dispatching/dispatcher-dashboard').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.commercialRoutes.list,
                                lazy: () =>
                                    import('../pages/commercial-dispatching/route-list').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.commercialRoutes.details(':id'),
                                lazy: () =>
                                    import('../pages/commercial-dispatching/route-details').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.routing(),
                                lazy: () =>
                                    import('../pages/commercial-dispatching/routing-view-wrapper').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.reschedule,
                                lazy: () =>
                                    import('../pages/commercial-dispatching/route-reschedule').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.schedule,
                                lazy: () =>
                                    import('../pages/commercial-dispatching/route-schedule').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.events,
                                lazy: () =>
                                    import('../pages/commercial-dispatching/event-list').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.dispatching.containerDelivery,
                                lazy: () =>
                                    import('../pages/commercial-dispatching/delivery-pickup-schedule/schedule').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.reports.list,
                                lazy: () => import('../pages/reports/report-list').then((module) => ({ Component: module.default })),
                            },
                            {
                                path: routes.reports.taxLiability,
                                lazy: () =>
                                    import('../pages/reports/sales-tax-liability-report').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.reports.paymentsBreakdown,
                                lazy: () =>
                                    import('../pages/reports/payments-breakdown-report').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.reports.rollOffSalesRevenue,
                                lazy: () =>
                                    import('../pages/reports/roll-off-sales-revenue').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.reports.rollOffDeliveries,
                                lazy: () =>
                                    import('../pages/reports/roll-off-deliveries').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.reports.rollOffBilling,
                                lazy: () =>
                                    import('../pages/reports/roll-off-billing').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.reports.rollOffOperations,
                                lazy: () =>
                                    import('../pages/reports/roll-off-operations').then((module) => ({
                                        Component: module.default,
                                    })),
                            },
                            {
                                path: routes.taskBoard,
                                lazy: () => import('../pages/task-board/task-board-page').then((module) => ({ Component: module.default })),
                            },
                        ],
                    },
                    {
                        path: routes.orders.details(':id'),
                        lazy: () => import('../components/OrderDetails').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.customers.details(':id'),
                        lazy: () => import('../components/CustomerDetails').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.customers.list,
                        lazy: () => import('../pages/customer-list/customer-list').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.billing.list,
                        lazy: () => import('../pages/billing/billing-page').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.pricing.list,
                        lazy: () => import('../pages/HaulerPricing/HaulerPricing').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.pricing.details(':id'),
                        lazy: () =>
                            import('../pages/HaulerPricingDetails/HaulerPricingDetails').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.requestForProposal.list,
                        lazy: () =>
                            import('../pages/sourgum-request-for-proposal/rfp-list').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.requestForProposal.details(':id'),
                        lazy: () =>
                            import('../pages/sourgum-request-for-proposal/rfp-details').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.requestForProposal.update(':id'),
                        lazy: () =>
                            import('../pages/sourgum-request-for-proposal/rfp-update').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.partnerPortalConnect.list,
                        lazy: () =>
                            import('../pages/partner-portal-connect/partner-portal-connect').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.settings.list,
                        lazy: () => import('../pages/account-settings').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.settings.updateAccount,
                        lazy: () =>
                            import('../pages/account-settings/pages/account-update').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.settings.accountingIntegrations,
                        lazy: () =>
                            import('../pages/account-settings/pages/accounting-integrations').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.settings.quickBooksOnlineMapping,
                        lazy: () =>
                            import('../pages/account-settings/pages/quickbooks-online-settings').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.settings.quickBooksOnlineAuthCallback,
                        lazy: () =>
                            import('../pages/account-settings/pages/QuickBooksOnlineAuthCallback').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.settings.quickBooksDesktopSettings,
                        lazy: () =>
                            import('../pages/account-settings/pages/quickbooks-desktop-settings').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.settings.quickBooksDesktopDownload,
                        lazy: () =>
                            import('../pages/account-settings/pages/quickbooks-desktop-download').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.users.update(':id'),
                        lazy: () => import('../pages/account-settings/pages/user-update').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.search.list,
                        lazy: () => import('../pages/GlobalSearch').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.commercialPreview,
                        lazy: () => import('../pages/commercial-preview').then((module) => ({ Component: module.default })),
                    },
                    ...(process.env.NODE_ENV === 'development'
                        ? [
                              {
                                  path: routes.devTest,
                                  lazy: () => import('../pages/dev-test').then((module) => ({ Component: module.default })),
                              },
                          ]
                        : []),
                    {
                        path: '*',
                        lazy: () => import('../structure/not-found').then((module) => ({ Component: module.default })),
                    },
                ],
            },
            {
                element: <PublicOnlyWrapper />,
                children: [
                    {
                        path: routes.auth.login,
                        lazy: () => import('../structure/Auth/login-page').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.auth.newPasswordRequired,
                        lazy: () =>
                            import('../structure/Auth/new-password-required-page').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.auth.forgotPassword,
                        lazy: () =>
                            import('../structure/Auth/forgot-password-page').then((module) => ({
                                Component: module.default,
                            })),
                    },
                    {
                        path: routes.public.signUp,
                        lazy: () => import('../pages/sign-up/sign-up-page').then((module) => ({ Component: module.default })),
                    },
                    {
                        path: routes.public.verifyAccount,
                        lazy: () => import('../pages/sign-up/verify-account').then((module) => ({ Component: module.default })),
                    },
                ],
            },
            // Access for both public and authenticated users
            {
                path: routes.public.updateAdmin,
                lazy: () => import('../pages/public/HaulerToAdminUpdates').then((module) => ({ Component: module.default })),
            },
            {
                path: routes.public.requestForProposal(':accessLink'),
                lazy: () => import('../pages/public/public-rfp-update').then((module) => ({ Component: module.default })),
            },
        ],
    },
]);

export default router;
