import { Hauler, Profile } from '@alliance-disposal/transport-types';
import {
    BanknotesIcon,
    BeakerIcon,
    ClipboardDocumentListIcon,
    ClockIcon,
    Cog6ToothIcon,
    CurrencyDollarIcon,
    MagnifyingGlassIcon,
    MapIcon,
    PresentationChartBarIcon,
    TruckIcon,
    ViewColumnsIcon,
    WrenchIcon,
} from '@heroicons/react/24/solid';

export const ACCESS = {
    customers: ['admin'],
    orders: ['admin'],
    schedules: ['admin'],
    equipment: ['admin'],
    driverTasks: ['admin'],
    // pricing: [
    //   'admin',
    // ],
    settings: ['admin'],
    search: ['admin'],
    billing: ['admin'],
    lab: ['admin'],
    appAdmin: ['appAdmin'],
};

export const routes = {
    dashboard: '/',
    auth: {
        login: '/login/',
        newPasswordRequired: '/new-password-required/',
        forgotPassword: '/forgot-password/',
        groups: 'auth',
    },
    public: {
        updateAdmin: '/update-alliance/',
        groups: 'public/',
        signUp: '/sign-up/',
        verifyAccount: '/verify/',
        requestForProposal: (accessLink: string) => `/public/request-for-proposal/${accessLink}/`,
    },
    orders: {
        list: '/orders/',
        create: '/orders/new/',
        update: (id: string) => `/orders/${id}/`,
        details: (id: string) => `/orders/details/${id}/`,
        groups: ACCESS.orders,
    },

    pricing: {
        list: '/pricing/',
        details: (id: string) => `/pricing/details/${id}/`,
        // create: '/orders/new',
        // update: (id: string) => `/orders/${id}`,
        // details: (id: string) => `/orders/details/${id}`,
        // groups: ACCESS.orders,
    },
    driverTracking: {
        list: '/driver-tracking/',
    },
    timeSheet: {
        list: '/time-sheet/',
    },

    settings: {
        list: '/settings/',
        updateAccount: '/settings/update/',
        generalSettings: '/settings/#general-settings',
        billingSettings: '/settings/#billing-settings',
        listUsers: '/settings/#users',
        accountingIntegrations: '/settings/accounting-integrations/',
        quickBooksOnlineMapping: '/settings/quickbooks-online/mapping/',
        quickBooksOnlineAuthCallback: '/settings/quickbooks-online/auth-callback/',
        quickBooksDesktopSettings: '/settings/quickbooks-desktop/',
        quickBooksDesktopDownload: '/settings/quickbooks-desktop-download/',
        groups: ACCESS.settings,
    },
    roScheduling: {
        list: '/ro-schedule/',
    },
    // pricing: {
    //   list: '/pricing',
    //   create: '/pricing/new',
    //   update: (id) => `/pricing/${id}`,
    //   groups: ACCESS.pricing
    // },
    equipment: {
        map: '/equipment/map/',
        inventory: '/equipment/inventory',
        vehicles: '/equipment/vehicles',
        containers: '/equipment/containers',
    },
    subscriptions: {
        create: '/subscriptions/new/',
        list: '/subscriptions/',
        details: (id: string) => `/subscriptions/details/${id}/`,
        editEquipment: (id: string) => `/subscriptions/edit-equipment/${id}/`,
    },
    dispatching: {
        dashboard: '/dispatching/',
        routing: (params?: { routeIDs?: string[]; selectedRouteID?: string; date?: string }) => {
            const searchParams = new URLSearchParams();
            if (params?.routeIDs) {
                searchParams.append('routeIDs', params.routeIDs.join(','));
                if (params?.selectedRouteID) {
                    searchParams.append('selectedRouteID', params.selectedRouteID);
                } else {
                    searchParams.append('selectedRouteID', params.routeIDs[0]);
                }
            }
            if (params?.date) searchParams.append('date', params.date);
            return `/dispatching/routing/${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;
        },
        containerDelivery: '/dispatching/container-delivery/',
        schedule: '/dispatching/schedule/',
        commercialRoutes: {
            list: '/dispatching/commercial-routes/',
            details: (id: string) => `/dispatching/commercial-routes/details/${id}/`,
        },
        reschedule: '/dispatching/reschedule/',
        events: '/dispatching/events/',
    },

    rollOffDumpsters: {
        list: '/equipment/',
        create: '/roll-off-dumpsters/new/',
        update: (id: string) => `/roll-off-dumpsters/${id}/`,
        details: (id: string) => `/roll-off-dumpsters/details/${id}/`,
        groups: ACCESS.equipment,
    },
    customers: {
        list: '/customers',
        // create: '/customers/new',
        update: (id: string) => `/customers/${id}/`,
        details: (id: string) => `/customers/details/${id}/`,
        groups: ACCESS.customers,
    },
    schedules: {
        list: '/schedules/',
        groups: ACCESS.schedules,
    },
    users: {
        update: (id: string) => `/users/${id}/`,
        list: '/settings/#users',
    },
    reports: {
        list: '/reports',
        taxLiability: '/reports/sales-tax-liability',
        paymentsBreakdown: '/reports/payments-breakdown',
        rollOffDeliveries: '/reports/roll-off-deliveries',
        rollOffSalesRevenue: '/reports/roll-off-sales-revenue',
        rollOffBilling: '/reports/roll-off-billing',
        rollOffOperations: '/reports/roll-off-operations',
    },
    search: {
        list: '/search/',
        groups: ACCESS.search,
    },
    billing: {
        list: '/billing/',
        rollOffDetails: (rolloff: string) => `/billing/?rolloff=${rolloff}`,
        subscriptionDetails: (id: string, receivableId?: string) =>
            `/billing/?subscription=${id}${receivableId ? `&receivable=${receivableId}` : ''}`,
        groups: ACCESS.billing,
    },
    requestForProposal: {
        list: '/request-for-proposal/',
        details: (id: string) => `/request-for-proposal/details/${id}/`,
        update: (id: string) => `/request-for-proposal/submit/${id}/`,
    },
    partnerPortalConnect: {
        list: '/partner-portal-connect/',
    },
    lab: {
        list: '/lab/',
        groups: ACCESS.lab,
    },
    appAdmin: {
        list: '/app-admin/',
        groups: ACCESS.appAdmin,
    },
    taskBoard: '/task-board/',
    devTest: '/dev-test/',
    commercialPreview: '/commercial-preview/',
    scheduleWaysteDemo: 'https://meetings.hubspot.com/wayste/wayste-demo',
};

export default routes;

export const routeList = (user: Profile.ProfileTransport, userHaulerInfo: Hauler.HaulerTransport, isAppAdmin = false) => {
    return [
        {
            text: 'Dashboard',
            path: routes.dashboard,
            exact: true,
            icon: <PresentationChartBarIcon />,
            disabled: false,
        },
        {
            text: 'Orders',
            path: routes.orders.list,
            exact: false,
            icon: <ClipboardDocumentListIcon />,
            groups: ACCESS.orders,
            disabled: false,
        },
        {
            text: 'Schedules',
            path: routes.roScheduling.list,
            exact: false,
            icon: <ViewColumnsIcon />,
            groups: ACCESS.schedules,
            disabled: userHaulerInfo.waysteLite,
        },
        {
            text: 'Billing',
            path: routes.billing.list,
            exact: false,
            icon: <BanknotesIcon />,
            groups: ACCESS.billing,
            disabled: false,
        },
        {
            text: 'Equipment',
            path: routes.rollOffDumpsters.list,
            exact: false,
            icon: <TruckIcon />,
            disabled: userHaulerInfo.waysteLite,
        },

        {
            text: 'Pricing',
            path: routes.pricing.list,
            exact: false,
            icon: <CurrencyDollarIcon />,
            disabled: false,
        },
        {
            text: 'Tracking',
            path: routes.driverTracking.list,
            exact: false,
            icon: <MapIcon />,
            disabled: userHaulerInfo.waysteLite,
        },
        {
            text: 'Time Sheet',
            path: routes.timeSheet.list,
            exact: false,
            icon: <ClockIcon />,
            disabled: userHaulerInfo.waysteLite,
        },
        {
            text: 'Search',
            path: routes.search.list,
            exact: false,
            icon: <MagnifyingGlassIcon />,
            disabled: false,
        },
        {
            text: 'Settings',
            path: routes.settings.list,
            exact: false,
            icon: <Cog6ToothIcon />,
            groups: ACCESS.schedules,
            disabled: false,
        },
        {
            text: 'Admin',
            path: routes.appAdmin.list,
            exact: false,
            icon: <WrenchIcon />,
            groups: ACCESS.appAdmin,
            hide: !isAppAdmin,
        },
        {
            text: 'Lab',
            path: routes.lab.list,
            exact: false,
            icon: <BeakerIcon />,
            groups: ACCESS.lab,
            hide: !isAppAdmin,
        },
    ].filter((route) => !route.disabled);
};
